import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { InfoHeader, SubHeading } from '../components/common'

const Center = styled(Grid)`
  margin: Auto;
`
const RightInfo = styled(Grid)`
    text-align: left;
    height: auto;

    color: #F2F2F2;
    font-size: 25px:
    font-weight: bold;
    display: inline-block;
    margin: auto 0 auto 100px;
`
const Placeholder = styled(Grid)`
  float: left;
  background: black;
  display: inline-block;
  vertical-align: middle;
  margin: auto 0;
`

const InfoContainer = styled(Grid)`
  height: 85vh;
  margin: 40px 0 50px 0;
  padding-top: 50px;
  background: black;
  box-shadow: inset 4px 4px 7px rgba(0, 0, 0, 0.25);
`

const Date = styled.span`
  color: #74b9ff;
  font-size: 25px;
`

export default function PageTemplate(
  { 
    data: { 
      mdx: {
        body,
        frontmatter: { 
          title, 
          date, 
          subheading
        }
      }
    }
  }
 ) {

  return (
    <Layout>
      <SEO title={`${title}`} />
      <Grid container>
        <InfoContainer item xs={12}>
          <Center container>
            <Placeholder item xs={4}></Placeholder>
            <RightInfo item xs={8}>
              <InfoHeader>
                {title} <Date>{date}</Date>
              </InfoHeader>
              <SubHeading>{subheading}</SubHeading>
              {body && <MDXRenderer>{body}</MDXRenderer>}
            </RightInfo>
          </Center>
        </InfoContainer>
      </Grid>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        subheading
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
